import React from 'react'

const page3 = () => {
  return (
    <div className='terms'>
      <h1>Terms of Service</h1>
  <p className='welcome'>
        Welcome to our website! By accessing or making a purchase from our site, you agree to be bound by the following Terms of Service ("Terms"), including any additional terms and policies referenced herein or available via hyperlinks. These Terms apply to all users of our site, including but not limited to browsers, vendors, customers, merchants, and content contributors.
        Please review these Terms carefully before using our site. By using or accessing any part of our site, you agree to comply with these Terms. If you disagree with any part of these Terms, you may not use our site or services. Acceptance of these Terms is solely governed by these Terms.
        Any new features or tools added to our store will also be governed by these Terms. You can always view the latest version of the Terms on this page. We reserve the right to update, modify, or replace any part of these Terms by posting updates on our website. It is your responsibility to review this page periodically for changes. Continued use of or access to the site following the posting of changes constitutes acceptance of those changes.
        Our store is hosted by Shopline Inc., which provides the online platform that enables us to sell our products and services to you.
</p>
<p className='welcome'>
SECTION 1 - ONLINE STORE TERMS
By agreeing to these Terms, you confirm that you are at least of legal age in your jurisdiction, or that you have obtained consent from a legal guardian to use this site if you are a minor. You agree not to use our products for illegal or unauthorized purposes and to comply with applicable laws, including copyright laws. You must not transmit harmful code or viruses. Any breach of these Terms will result in immediate termination of your access to our services.
</p>

<p className='welcome'>
SECTION 2 - GENERAL CONDITIONS
We reserve the right to refuse service to anyone at any time for any reason. Your content (excluding credit card information) may be transmitted unencrypted over various networks and may be modified to meet technical requirements. Credit card information is always encrypted during transmission. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any part of the Service or access to the Service without our express written permission. Headings in these Terms are for convenience only and do not limit the Terms.
</p>   

<p className='welcome'>
SECTION 3 - ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION
We are not responsible for inaccuracies or omissions on our site. The information provided is for general purposes and should not be relied upon without consulting more accurate sources. Any reliance on the information on this site is at your own risk. Historical information provided is for reference only and may not be current. We have no obligation to update information on our site, and you are responsible for monitoring changes.
</p>   

<p className='welcome'>
SECTION 4 - CHANGES TO SERVICES AND PRICES
Product prices are subject to change without notice. We reserve the right to modify or discontinue the Service (or any part of it) without prior notice. We are not liable for any changes, price modifications, or discontinuation of the Service.
</p>   

<p className='welcome'>
SECTION 5 - PRODUCTS OR SERVICES
Certain products or services may be available exclusively online. These may be limited in quantity and are subject to our Return Policy. We strive to display accurate colors and images, but cannot guarantee the accuracy of your monitor's display. We reserve the right to limit sales to any person, region, or jurisdiction and to change product descriptions and pricing without notice. We may discontinue products at any time. Offers are void where prohibited. We do not guarantee that products or services will meet your expectations or that errors will be corrected.
</p>   

<p className='welcome'>
SECTION 6 - BILLING AND ACCOUNT INFORMATION
We reserve the right to refuse any order and may limit or cancel quantities purchased. This includes orders placed with the same customer account, credit card, or billing address. If we make changes to or cancel an order, we will attempt to notify you using the contact information provided. You agree to provide accurate and complete purchase and account information and to update it as necessary.
</p>   

<p className='welcome'>
SECTION 7 - OPTIONAL TOOLS
We may provide access to third-party tools over which we have no control. You agree that we provide these tools "as is" and without warranties. We are not liable for any issues arising from your use of these tools. Your use of third-party tools is at your own risk, and you should review the third-party provider's terms.
</p>   

<p className='welcome'>
SECTION 8 - THIRD-PARTY LINKS
Our Service may include content from third parties and links to external websites. We are not responsible for the accuracy or content of these third-party sites. We are not liable for any issues related to third-party goods or services. Review third-party policies before engaging in transactions.
</p>   

<p className='welcome'>
SECTION 9 - USER COMMENTS AND SUBMISSIONS
If you send us submissions or feedback, you grant us a perpetual, non-exclusive license to use, edit, and publish these materials. We have no obligation to maintain confidentiality or provide compensation for these submissions. We may monitor and remove content that we find objectionable.
</p>   

<p className='welcome'>
SECTION 10 - PERSONAL INFORMATION
Your submission of personal information is governed by our Privacy Policy, which you can review on our site.
</p>   

<p className='welcome'>
SECTION 11 - ERRORS AND OMISSIONS
Occasionally, there may be errors or omissions on our site related to product descriptions, pricing, or availability. We reserve the right to correct any such errors and to update or cancel orders as necessary. We are not obligated to update information on our site except as required by law.
</p>   

<p className='welcome'>
SECTION 12 - PROHIBITED USES
You may not use the site or its content for unlawful purposes, to solicit unlawful acts, violate regulations, infringe intellectual property rights, harass or discriminate, submit false information, upload malicious code, collect personal information, spam, or interfere with the site’s security features. We reserve the right to terminate your use for violating these prohibitions.
</p>   

<p className='welcome'>
SECTION 13 - DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
We do not guarantee uninterrupted, timely, secure, or error-free service. We do not warrant the accuracy or reliability of results from using the Service. Your use of the Service is at your own risk. The Service is provided "as is" without warranties of any kind. We are not liable for any damages arising from the use of the Service or any related products, including indirect or consequential damages.
</p>   

<p className='welcome'>
SECTION 14 - INDEMNIFICATION
You agree to indemnify and hold harmless us and our affiliates from any claims arising from your breach of these Terms or violations of law.
</p>   

<p className='welcome'>
SECTION 15 - SEVERABILITY
If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in effect.
</p>   

<p className='welcome'>
SECTION 16 - TERMINATION
The obligations and liabilities incurred before the termination of this agreement will survive. These Terms are effective until terminated by either party. You may terminate by ceasing use of our site or services. We may terminate if we suspect non-compliance with these Terms.
</p>   

<p className='welcome'>
SECTION 17 - ENTIRE AGREEMENT
These Terms, along with any posted policies, constitute the entire agreement between you and us regarding the use of the Service. Any previous agreements are superseded by these Terms.
</p>   

<p className='welcome'>
SECTION 18 - GOVERNING LAW
These Terms are governed by the laws of Nigeria.
</p>   

<p className='welcome'>
SECTION 19 - CHANGES TO TERMS OF SERVICE
We may update these Terms at our discretion. Check this page periodically for changes. Continued use of the site constitutes acceptance of any changes.
</p>   

<p className='welcome'>
SECTION 20 - DISCOUNTS, PROMOTIONS, AND COMPETITIONS
Discounts are subject to our Terms of Service, Privacy Policy, and any specific terms for Business or Personal Customers. Discount codes must be applied at checkout and cannot be combined with other offers. Valid only for previous customers or specified conditions. We reserve the right to modify or withdraw offers. Not applicable to sale items. We are not responsible for any financial loss from promotions or inability to use them.
 </p>   
    </div>
  )
}

export default page3
