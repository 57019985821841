import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../context/shop-context';
import { FaShoppingCart } from 'react-icons/fa';
import './cart-icon.css';

const CartIcon = () => {
  const navigate = useNavigate();
  const { cartItems } = useContext(ShopContext);

  const totalProductCount = Object.values(cartItems).reduce((total, quantity) => total + quantity, 0);

  return (
    <div className="cart-icon" onClick={() => navigate('/cart')}>
      <FaShoppingCart size={24} />
      {totalProductCount > 0 && (
        <span className="cart-count">{totalProductCount}</span>
      )}
    </div>
  );
};

export default CartIcon;
