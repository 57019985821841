import React, { createContext, useState, useEffect } from 'react';
import { fetchProducts } from '../firebase/productService';

export const ShopContext = createContext();

export const ShopContextProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [cartItems, setCartItems] = useState(() => {
        // Load cart items from localStorage if available
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : {};
    });

    useEffect(() => {
        const getProducts = async () => {
            try {
                const productsFromFirestore = await fetchProducts();
                setProducts(productsFromFirestore);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        getProducts();
    }, []);

    useEffect(() => {
        // Save cart items to localStorage on update
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (itemId) => {
        setCartItems((prev) => {
            const updatedCart = { ...prev, [itemId]: (prev[itemId] || 0) + 1 };
            return updatedCart;
        });
    };

    const removeFromCart = (itemId) => {
        setCartItems((prev) => {
            const updatedCart = { ...prev };
            if (updatedCart[itemId] > 1) {
                updatedCart[itemId]--;
            } else {
                delete updatedCart[itemId];
            }
            return updatedCart;
        });
    };

    const updateCartItemCount = (count, itemId) => {
        if (count < 1) return; // Prevent setting quantity below 1

        setCartItems((prev) => ({ ...prev, [itemId]: count }));
    };

    const getTotalCartAmount = () => {
        return Object.keys(cartItems).reduce((total, id) => {
            const product = products.find(p => p.id === id);
            return total + (product ? product.price * cartItems[id] : 0);
        }, 0);
    };

    return (
        <ShopContext.Provider
            value={{
                products,
                cartItems,
                addToCart,
                removeFromCart,
                updateCartItemCount,
                getTotalCartAmount,
            }}
        >
            {children}
        </ShopContext.Provider>
    );
};
