import { db } from './firebaseConfig';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  getDocs,
  deleteDoc
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { createSlug } from '../slugutils';

// Fetch all products
export const fetchProducts = async () => {
  try {
    const productsCollection = collection(db, 'products');
    const productSnapshot = await getDocs(productsCollection);
    const productList = productSnapshot.docs.map(doc => {
      const productData = { id: doc.id, ...doc.data() };
      return { ...productData, slug: createSlug(productData.productName) };
    });
    return productList;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

// Fetch product by slug
export const fetchProductBySlug = async (slug) => {
  try {
    const productsCollection = collection(db, 'products');
    const productSnapshot = await getDocs(productsCollection);
    const products = productSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      slug: createSlug(doc.data().productName)
    }));
    const product = products.find(product => product.slug === slug);
    return product || null;
  } catch (error) {
    console.error("Error fetching product by slug:", error);
    return null;
  }
};

// Fetch product by ID
export const fetchProductById = async (id) => {
  try {
    console.log(`Fetching product with ID: ${id}`);

    if (!id || typeof id !== 'string') {
      throw new Error('Invalid ID provided to fetchProductById');
    }

    const productDoc = doc(db, 'products', id);
    const productSnapshot = await getDoc(productDoc);

    if (productSnapshot.exists()) {
      const data = productSnapshot.data();
      const additionalImages = Array.isArray(data.additionalImages) ? data.additionalImages.slice(0, 4) : [];
      return { 
        id: productSnapshot.id, 
        ...data, 
        additionalImages, 
        slug: createSlug(data.productName),
        colorPrices: data.colorPrices || [] // Include colorPrices in the returned data
      };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching product:", error);
    return null;
  }
};

// Add a new product
export const addProductToFirestore = async (newProduct) => {
  try {
    const productWithSlug = { 
      ...newProduct, 
      slug: createSlug(newProduct.productName),
      colorPrices: newProduct.colorPrices || [] // Ensure colorPrices is included
    };
    const productsCollection = collection(db, 'products');
    await addDoc(productsCollection, productWithSlug);
  } catch (error) {
    console.error("Error adding product:", error);
  }
};

// Update an existing product
export const updateProductInFirestore = async (id, updatedProduct) => {
  try {
    const productDoc = doc(db, 'products', id);
    const updatedData = { ...updatedProduct };

    // Conditionally update slug only if productName is provided
    if (updatedProduct.productName) {
      updatedData.slug = createSlug(updatedProduct.productName);
    }

    // Ensure colorPrices is included in the update
    if (updatedProduct.colorPrices) {
      updatedData.colorPrices = updatedProduct.colorPrices;
    }

    await updateDoc(productDoc, updatedData);
  } catch (error) {
    console.error("Error updating product:", error);
  }
};

// Delete a product
export const deleteProductFromFirestore = async (id) => {
  try {
    const productDoc = doc(db, 'products', id);
    await deleteDoc(productDoc);
    console.log(`Product with ID: ${id} deleted successfully`);
  } catch (error) {
    console.error("Error deleting product:", error);
  }
};

// Fetch product images from Firebase Storage
export const fetchProductImages = async (imagePaths) => {
  try {
    const storage = getStorage();
    const urls = await Promise.all(
      imagePaths.map(async (path) => {
        const imageRef = ref(storage, path);
        return await getDownloadURL(imageRef);
      })
    );
    return urls;
  } catch (error) {
    console.error("Error fetching product images:", error);
    return [];
  }
};

// Upload an image to Firebase Storage
export const uploadImageToStorage = async (file, imagePath) => {
  if (!file) return null;

  try {
    const storage = getStorage();
    const storageRef = ref(storage, `${imagePath}/${file.name}`);
    
    await uploadBytes(storageRef, file);
    
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image to Firebase Storage:', error);
    throw error;
  }
};

// Upload an image from a URL to Firebase Storage
export const uploadImageToFirebase = async (imageUrl, folderName) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const storage = getStorage();
    const imageRef = ref(storage, `${folderName}/${Date.now()}`);

    const snapshot = await uploadBytes(imageRef, blob);

    const downloadUrl = await getDownloadURL(snapshot.ref);
    return downloadUrl;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error;
  }
};