import React, { useContext } from 'react';
import { ShopContext } from '../context/shop-context';
import { Link } from 'react-router-dom';
import { createSlug } from '../slugutils'; // Correct path to the slug utils

import './product.css';

export const Product = (props) => {
    const { id, productName, price, displayImage } = props.data;

    const context = useContext(ShopContext);
    const { addToCart, cartItems } = context || {};

    const cartItemAmount = cartItems[id] || 0;

    if (!context || !addToCart) {
        console.error('ShopContext is not available or addToCart is not defined');
        return null;
    }

    const formattedPrice = Number(price).toLocaleString('en-NG', { minimumFractionDigits: 2 });
    const productSlug = createSlug(productName);

    return (
        <div className='product'>
            <div className='product-image-container'>
                <Link to={`/product/${productSlug}`}>
                    <img className='product-image' src={displayImage} alt={productName} />
                </Link>
                <button 
                    className='addtocart' 
                    onClick={() => addToCart(id)}
                >
                    Add To Cart {cartItemAmount > 0 && <> ({cartItemAmount})</>}
                </button>
            </div>
            <div className='product-info'>
                <Link to={`/product/${productSlug}`}>
                    <h3 className='product-name'>{productName}</h3>
                </Link>
                <h3>₦{formattedPrice}</h3>
            </div>
        </div>
    );
};
