import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

const PrivateRoute = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
                sessionStorage.removeItem('authenticated'); 
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        authenticated ? (
            children
        ) : (
            <Navigate 
                to="/login" 
                state={{ from: location }} 
                replace 
            />
        )
    );
};

export default PrivateRoute;
