import React, { useState } from 'react';
import { auth } from '../firebase/firebaseConfig'; 
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import './Login.css'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            sessionStorage.setItem('authenticated', 'true'); 
            navigate('/adminPage'); 
        } catch (error) {
            setError('Failed to log in. Please check your credentials.');
        }
    };

    return (
        <div className='log'>
            <h2>Login</h2>
            {error && <p>{error}</p>}
            <form onSubmit={handleLogin}>
                <div>
                    <label>Email:</label>
                    <input 
                        className='mail-inp'
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input 
                        className='mail-inp'
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                </div>
                <button className='login-btn' type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
