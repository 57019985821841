

import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShopContext } from '../context/shop-context';
import { fetchProductBySlug } from '../firebase/productService';
import Slideshow from './slideshow';
import './product1.css';

// Helper function to parse content from Quill
const parseEditorContent = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const images = Array.from(doc.querySelectorAll('img')).map(img => img.src);
  const captions = Array.from(doc.querySelectorAll('p')).map(p => p.textContent.trim()).filter(text => text.length > 0);

  return { images, captions };
};

export const Product1 = () => {
  const [displayImage, setDisplayImage] = useState('');
  const { updateCartItemCount, cartItems } = useContext(ShopContext);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isStripVisible, setIsStripVisible] = useState(false);
  const addToCartButtonRef = useRef(null);

  const [slideshowImages, setSlideshowImages] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [captions, setCaptions] = useState([]);

  // New state for selected color and current price
  const [selectedColor, setSelectedColor] = useState('');
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = await fetchProductBySlug(slug); 
        setProduct(product);
        
        if (product && product.displayImage) {
          setDisplayImage(product.displayImage);
        }

        if (product && product.slideshowImages) {
          setSlideshowImages(product.slideshowImages);
        }

        if (product && product.quillContent) {
          const { images, captions } = parseEditorContent(product.quillContent);
          setAdditionalImages(images);
          setCaptions(captions);
        }

        // Set initial color and price
        if (product && product.colorPrices && product.colorPrices.length > 0) {
          setSelectedColor(product.colorPrices[0].color);
          setCurrentPrice(product.colorPrices[0].price);
        } else {
          // Fallback to the original price if no colorPrices are defined
          setCurrentPrice(product.price);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (slug) {
      getProduct();
    } else {
      console.error('Product slug is undefined');
    }
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      if (addToCartButtonRef.current) {
        const rect = addToCartButtonRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsStripVisible(!isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [addToCartButtonRef.current]);

  if (!product) return <p>Loading...</p>;

  const { productName, description, colors } = product;

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setQuantity(isNaN(value) ? 1 : value);
  };

  const handleAddToCart = () => {
    if (quantity > 0) {
      updateCartItemCount(cartItems[product.id] ? cartItems[product.id] + quantity : quantity, product.id);
    }
  };

  const handleColorChange = (colorPrice) => {
    setSelectedColor(colorPrice.color);
    setCurrentPrice(colorPrice.price);
  };

  const cartItemAmount = cartItems[product.id] || 0;

  return (
    <div>
    <div className='orderpage'>
      <div className='top-one'>
        <Slideshow imagePaths={slideshowImages} />
      </div>      
      <div className='two'>
        <div className='product1'>
          <div className='describe'>
            <p className='ppname'><b>{product.productName}</b></p>
            <p className='pricepp'>₦{currentPrice}</p>
            <p className='product-description'>{product.description}</p>
            {product.colorPrices && product.colorPrices.length > 0 && (
              <div className='color-selection'>
                <p>Select Size:</p>
                {product.colorPrices.map((colorPrice, index) => (
                  <button
                    key={index}
                    onClick={() => handleColorChange(colorPrice)}
                    className={`color-button ${selectedColor === colorPrice.color ? 'selected' : ''}`}
                    style={{ backgroundColor: colorPrice.color }}
                  >
                    {colorPrice.color}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='more'>
          <div className='warns'>
            <div className='warns2'>
              <h5>Ships from Lagos, 2 day delivery.</h5>
              <h5>7 Days Free Returns.</h5>
              <h5>Secure and efficient payment options.</h5>
              <h5>Safe delivery, damage replacement.</h5>
            </div>
            <div className='buy'>
              <div className='countHandler' ref={addToCartButtonRef}>
                <div className='tch'>
                  <h5>Quantity</h5>
                  <div className='countHandlerplus2'>
                    <button className='plus' onClick={() => setQuantity(prevQuantity => Math.max(prevQuantity - 1, 0))}> &lt;</button>
                    <input 
                      className='inp'
                      type="number" 
                      min="1" 
                      value={quantity} 
                      onChange={handleQuantityChange} 
                    />
                    <button className='plus' onClick={() => setQuantity(quantity + 1)}> &gt; </button>
                  </div>
                </div>
                <div className='newbtn'>
                  <button className='cartit' onClick={handleAddToCart}>
                    Add To Cart {cartItemAmount > 0 && <> ({cartItemAmount})</>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>      
        </div>

        {additionalImages.map((img, index) => (
          <div key={index} className={`more-details${index + 1}`}>
            <img className={`more-details-image${index + 1}`} src={img} alt={`Additional ${index + 1}`} />
            <p className='textss'>{captions[index]}</p>
          </div>
        ))}
      </div>

      <div className={`bottom-strip ${isStripVisible ? 'visible' : ''}`}>
        <div className='left'>
          <img src={displayImage} alt={productName} className='bottom-strip-image' />
          <div className='infor'>
            <p className='bottomstripname'>{productName}</p>
            <p className='bottomstripprice'>₦{currentPrice}</p>
          </div>
        </div>
        <div className='right'>
          <div className='countHandlerplus2'>
            <button className='plus' onClick={() => setQuantity(prevQuantity => Math.max(prevQuantity - 1, 0))}> &lt;</button>
            <input 
              className='inp2'
              type="number" 
              min="1" 
              value={quantity} 
              onChange={handleQuantityChange} 
            />
            <button className='plus' onClick={() => setQuantity(quantity + 1)}> &gt; </button>
          </div>
          <button className='stripcartit' onClick={handleAddToCart}>
            Add To Cart {cartItemAmount > 0 && <> ({cartItemAmount})</>}
          </button>
        </div>
      </div>    
      </div>
  );
};