import React, { useContext } from 'react';
import { ShopContext } from '../context/shop-context';
import { CartItem } from '../components/cart-item';
import './cart.css';
import { useNavigate } from 'react-router-dom';

export const Cart = () => {
    const { cartItems, getTotalCartAmount, products } = useContext(ShopContext);
    const totalAmount = getTotalCartAmount();
    const navigate = useNavigate();

    const cartProductItems = products.filter(product => cartItems[product.id] > 0);
    const isCartEmpty = cartProductItems.length === 0;

    return (
        <div className='cart'>
            <div>
                <h1>Your cart</h1>
            </div>
            <div className='cartItems'>
                {cartProductItems.length > 0 ? (
                    cartProductItems.map((product) => (
                        <CartItem 
                            key={product.id} 
                            data={product} 
                            quantity={cartItems[product.id]} 
                        />
                    ))
                ) : (
                    <h1>Your cart is empty</h1>
                )}
                <p>Subtotal: ₦{ totalAmount.toLocaleString('en-NG', { minimumFractionDigits: 2 }) }</p>
                <div className='checkout-buttons'>
                    <button className='blehh' onClick={() => navigate('/shop')}>Continue Shopping</button>
                    <button 
                        className='blehh' 
                        onClick={() => navigate('/Checkout')} 
                        disabled={isCartEmpty}  
                    >
                        Checkout
                    </button>
                </div>
            </div>
        </div>
    );
};
