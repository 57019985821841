import React, { useState } from 'react';
import axios from 'axios';
import './page1.css'

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });


    if (formData.name && formData.email && formData.subject && formData.message) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!isFormValid) {
      alert('Please fill out all fields before submitting.');
      return;
    }

    const emailData = {
      sender: { name: formData.name, email: formData.email },
      to: [{ email: 'westernontime@gmail.com', name: 'Your Name' }],
      subject: formData.subject,
      htmlContent: `<p>${formData.message}</p>`,
    };

    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/smtp/email',
        emailData,
        {
          headers: {
            'api-key': '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',
            'Content-Type': 'application/json',
          }
        }
      );
      console.log('Email sent successfully:', response.data);
      alert('Support request sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending support request. Please try again later.');
    }
  };

  return (
    <div className="support-form-container">
      <h2>Contact Support</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Subject*</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message*</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <button type="submit" disabled={!isFormValid}>
          Submit
        </button>
        <h6 color='red'>Note: fields marked (*) are required.</h6>
      </form>
    </div>
  );
};

export default SupportForm;
