import React from 'react'

const page4 = () => {
  return (
    <div>
      <h4>If you wish to return an item, kindly reach out to us via <a href="mailto:support@xyzautoslimited.com">support@xyzautoslimited.com</a> or any of our socials      and we can help process the return of the product and 
        refund of your money.
      </h4>
    </div>
  )
}

export default page4
