import React from 'react'
import './page2.css'

function page2() {
  return (
    <div className='shippin-policy'>        
      <h1>
        Shipping Policy
      </h1>
      <h2> Delivery Time</h2>
          <p>
            After placing an order and completing the inspection of all products in your order, your parcel will be prepared and sent for delivery.
            Standard Shipping within Nigeria: 5 to 10 business days
          </p>
          <h2>Shipping Locations</h2>
          <p>
              We currently only ship within Nigeria. Deliveries are available to all states and locations across the country.
          </p>
          <p>
            Once an order has been placed and shipped, it cannot be canceled. Please ensure that all shipping, billing, and email information is correct before submitting payment.
            We will choose the carrier that we think works best for our customers. Speedaf. Or, our very own in-house dispatch.
          </p>
          <h2> Shipping Information</h2>
          <p>
              Orders are not shipped after business hours on Friday, and shipments are not made on weekends or public holidays. If your order hasn't been dispatched yet, you can still change the shipping address, email address, or details of the items ordered (e.g., size or color).
              In rare cases, orders may be delayed for inspection at checkpoints or due to local circumstances, but rest assured, your package will be delivered as soon as possible.
              Please note that delivery times may be affected by holidays, weather conditions, and other unforeseen variables, which may extend shipping times. 
          </p>
    </div>
  )
}

export default page2