import React, { useState, useEffect } from 'react';
import './slideshow.css';

const Slideshow = ({ imagePaths }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  // Auto-slide every 5 seconds
  useEffect(() => {
    const autoSlide = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(autoSlide);
  }, [imagePaths.length]);

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;
      if (newIndex >= imagePaths.length) return 0;
      if (newIndex < 0) return imagePaths.length - 1;
      return newIndex;
    });
  };

  const selectSlide = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className="slideshow-container">
      {imagePaths.map((src, index) => (
        <div
          key={index}
          className={`mySlides fade ${index === slideIndex ? 'active' : ''}`}
          style={{ display: index === slideIndex ? 'block' : 'none' }} // Only show active slide
        >
          <img src={src} style={{ width: '100%' }} alt={`Slide ${index + 1}`} />
        </div>
      ))}

      <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
      <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>

      {/* Radio buttons to indicate current slide */}
      <div className="radio-buttons">
        {imagePaths.map((_, index) => (
          <label key={index}>
            <input
              type="radio"
              name="slide-selector"
              checked={index === slideIndex}
              onChange={() => selectSlide(index)}
            />
            <span className={`radio-dot ${index === slideIndex ? 'active' : ''}`}></span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
