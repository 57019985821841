import React from 'react';

const page5 = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <h1>Privacy Policy</h1>
      <p><strong>XYZ AUTOS LIMITED Privacy Policy</strong></p>
      <p><strong>Effective date: 15/09/2024</strong></p>
      <p>
        This Privacy Policy applies to certain information, as defined below, that XYZ AUTOS LIMITED, and its affiliates ("XYZ AUTOS LIMITED," "our," "we" or "us") collects through our websites, products, and applications (the "Services").
      </p>
      <p><strong>YOUR PRIVACY IS IMPORTANT TO US. PLEASE READ THIS PRIVACY POLICY TO LEARN ABOUT THE INFORMATION THAT XYZ AUTOS LIMITED COLLECTS FROM YOU AND HOW WE USE THAT INFORMATION.</strong></p>
      <p>
        BY USING THE SERVICES YOU CONSENT TO THE COLLECTION, USE, AND TRANSFER OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY, THEN PLEASE DO NOT USE THE SERVICES.
      </p>
      
      <h2>What information do we collect?</h2>
      <h3>Information Provided by Users</h3>
      <ul>
        <li>
          <strong>Your Account information:</strong> You provide your email, or log in via a third-party account (e.g., Facebook) to create an online or application account ("Account") or subscribe to our communications. If you refuse to provide such information, you may not be able to use the Services properly. When creating your Account, you may be asked to enter information such as: your name, avatar, age, gender, address, telephone number, and email address.
        </li>
        <li>
        </li>
        <li>
          <strong>Customer Support:</strong> When you contact us for support, you may provide information regarding your use of our Services or products, including your contact details and details about any issues you are experiencing.
        </li>
      </ul>

      <h3>Product Usage Information</h3>
      <ul>
        <li>
          <strong>Product monitoring:</strong> We collect information about how you use our products, including functions used, time, frequency, and duration of your activities.
        </li>
        <li>
          <strong>Services utilization:</strong> We collect information stored in our products and report it as part of the Services.
        </li>
      </ul>

      <h3>Device and Other Automatically Collected Information</h3>
      <ul>
        <li>
          <strong>Device properties and operations:</strong> We collect information such as your device number, hardware and software version, and Internet Protocol (IP) addresses.
        </li>
        <li>
          <strong>Device signals and logs:</strong> We collect information sent or received by your device, such as geo-location, IP addresses, Wi-Fi access points, and cell towers. We also maintain server logs, which include your search queries and other metadata.
        </li>
        <li>
          <strong>Cookies and Web Beacons:</strong> We use cookies to store data about your activity on our Services. You can learn more about cookies at <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>.
        </li>
      </ul>

      <h2>What do we use your information for?</h2>
      <p>We may use your information in the following ways:</p>
      <ul>
        <li>To process transactions and payments.</li>
        <li>To send you updates related to your orders or communications regarding new products and services.</li>
        <li>To operate, support, and improve our Services.</li>
      </ul>

      <h2>How is this information shared?</h2>
      <p>We share your information with:</p>
      <ul>
        <li><strong>Third-party providers:</strong> who help us operate and improve our Services.</li>
        <li><strong>Third-party services:</strong> such as login providers (e.g., Facebook, Google).</li>
        <li><strong>Legal entities:</strong> to comply with regulatory requirements or to protect our rights.</li>
      </ul>

      <h2>How do we protect your information?</h2>
      <p>
        We implement various security measures, including Secure Socket Layer (SSL) technology, to protect your personal and payment information. We do not store your credit card information on our servers after a transaction.
      </p>

      <h2>Third-Party Links</h2>
      <p>
        Our Services may contain links to third-party websites. These websites have independent privacy policies, and we are not responsible for their content.
      </p>

      <h2>Changes to Our Privacy Policy</h2>
      <p>
        XYZ AUTOS LIMITED reserves the right to update this Privacy Policy periodically. Any changes will be effective immediately upon posting, and your continued use of the Services will constitute your acceptance of these changes.
      </p>

      <h2>How can you contact XYZ AUTOS LIMITED with questions?</h2>
      <p>If you have any questions or concerns, please contact us via email:</p>
      <p><strong>Email Us:</strong> <a href="mailto:support@xyzautoslimited.com">support@xyzautoslimited.com</a></p>
    </div>
  );
};

export default page5;
