
import React, { useContext } from 'react';
import { ShopContext } from '../context/shop-context';
import { Product } from './product';
import './shop.css';

export const Shop = () => {
    const { products } = useContext(ShopContext);

    return (
        <div className='shop'>
            <div className='title'>
                <h1>Welcome</h1>
                <h3> Free Shipping over ₦10,000 Nationwide!</h3>
            </div>
            <div className='products'>
                {products.length > 0 ? (
                    products.map((product) => (
                        <Product key={product.id} data={product} />
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};
