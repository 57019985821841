import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa'; 
import './cart-icon.css';

const UserIcon = () => {
  const navigate = useNavigate();
  
  return (
    <div className="cart-icon" onClick={() => navigate('/adminPage')}>
      <FaUser size={24} />  
     
    </div>
  );
};

export default UserIcon;
