import React from 'react';
import './footer.css';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { FaWhatsapp, FaFacebook} from 'react-icons/fa';


const App = () => {
  return (
    <div className="page-container">
      <div className="content-wrap">
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="logo-text">XYZ AUTOS</h2>
        </div>

        <div className="footer-section list">
          <h3 className='nawamehn'>Quick Links</h3>
          <ul>
            <CustomLink to='/Return and exchanges'>Return and exchanges</CustomLink>
            <CustomLink to='/Shipping Policy'>Shipping policy</CustomLink>
            <CustomLink to='/Privacy policy'>Privacy policy</CustomLink>
            <CustomLink to='/Terms Of Service'>Terms of Service</CustomLink>
            <CustomLink to='/Socials'>Contact</CustomLink>
          </ul>
        </div>
        <div className="footer-section-contact">
          <h3 className='nawamehn'>Contact Us</h3>
          <p><span>Address:</span>1, Waheed Smith Street, Olive Estate, Apple Junction, Amuwo Odofin, Lagos, Nigeria.</p>
          <p>support@xyzautoslimited.com</p>
          <a href="https://wa.me/qr/NNKI5NF7VWSTD1" target="_blank" className="whatsapp-link">
          <p>
            <FaWhatsapp className='whatsapp'/>
            Phone: +2348027626630
            </p>
            </a>
            <a href="https://web.facebook.com/profile.php?id=100085708008550" target="_blank" className="whatsapp-link">
          <p>
          <FaFacebook className='facebook'/>
          XYZ AUTOS LIMITED
            </p>
            </a>
        </div>
      </div>
    </footer>
  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? 'active' : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default App;
