import React, { useContext } from 'react';
import { ShopContext } from '../context/shop-context';

export const CartItem = (props) => {
    const { id, productName, price, displayImage } = props.data;
    const { addToCart, removeFromCart, updateCartItemCount } = useContext(ShopContext);

    const itemCount = props.quantity || 0;

    return (
        <div className='cartItem'>
            <img className="nawaplus" src={displayImage} alt={productName} />
            <div className='describe'>
                <h4>{productName}</h4>
                {/* Use Number(price) to ensure price is a number */}
                <h3>₦{Number(price).toLocaleString('en-NG')}</h3>
                <div className='cartcountHandler'>
                    <button className='plus' onClick={() => removeFromCart(id)}> - </button>
                    <input 
                        type="number" 
                        min="0" 
                        value={itemCount} 
                        onChange={(e) => updateCartItemCount(Number(e.target.value), id)} 
                    />
                    <button className='plus' onClick={() => addToCart(id)}> + </button>
                </div>
            </div>
        </div>
    );
};
