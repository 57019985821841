import React, { useEffect, useState } from "react";
import { fetchProducts } from "../firebase/productService";
import { Product } from "./product"; // Ensure this path is correct

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const productsFromFirestore = await fetchProducts();
        setProducts(productsFromFirestore || []); // Ensure products is always an array
      } catch (error) {
        console.error("Error fetching products: ", error);
        setProducts([]); // Set to empty array on error
      }
    };

    getProducts();
  }, []);

  return (
    <div className="product-list">
      {products && products.length > 0 ? (
        products.map((product) => (
          <Product key={product.id} data={product} />
        ))
      ) : (
        <p>No products available</p>
      )}
    </div>
  );
};

export default ProductList;
