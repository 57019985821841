import React, { useState, useEffect, useRef } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './hamburger.css';

function Hamburger() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="Hamburger">
      <button className="hamburger-btn" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav ref={menuRef} className={`menu ${isOpen ? 'open' : ''}`}>
        <CustomLink to='/'>Home</CustomLink>
        <CustomLink to='/Socials'>Contact</CustomLink>
        <CustomLink to='/Terms Of Service'>Terms Of Service</CustomLink>
      </nav>
    </div>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? 'active' : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Hamburger;
