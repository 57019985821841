import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import Navbar from './components/Navbar';
import Page1 from "./pages/page1";
import Page2 from './pages/page2';
import Page3 from './pages/page3';
import Page4 from './pages/page4';
import Hamburger from './components/hamburger'
import Page5 from './pages/page5';
import { Product1 } from './products/product1';
import Footer from './components/footer';
import Rando from './products/rando';
import { Cart } from './components/cart';
import { Shop } from './components/shop';
import { ShopContextProvider } from './context/shop-context';
import AdminPage from '../src/adminPage';
import ProductList from './components/productList';
import PrivateRoute from './components/privateRoute';
import Login from './components/Login';
function App() {
  return (
    <>
      <ShopContextProvider>
        <div className='top'>
          <Hamburger />
          <Navbar />
        </div>
        <div className='container'>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/adminPage" element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            } />
            <Route path="/products" element={<ProductList />} />
            <Route path="/" element={<Shop />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/Socials" element={<Page1 />} />
            <Route path="/Shipping Policy" element={<Page2 />} />
            <Route path="/Terms Of Service" element={<Page3 />} />
            <Route path="/Return and exchanges" element={<Page4 />} />
            <Route path="/Privacy policy" element={<Page5 />} />
            <Route path="/Checkout" element={<Rando />} />
            <Route path="/product/:slug" element={<Product1 />} />
          </Routes>
        </div>
        <Footer />
      </ShopContextProvider>
    </>
  );
}

export default App;
