import React from 'react'
import CartIcon from './cart-icon'; 
import UserIcon from './user-icon';
import AdminPage from '../adminPage'

import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import './Navbar.css'

export default function Navbar() {
  return (
    <nav className='nav'>
        <div className='top-nav'>
            <div className='top-mid'>
            <Link to='/' className='site-title'>
               XYZ AUTOS
            </Link>
            </div>
            <div className='cart-contain'>
                <UserIcon />
                <CartIcon />        
            </div>
           
        </div>   
    </nav>
  )
}