import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addProductToFirestore,
  fetchProducts,
  deleteProductFromFirestore,
  updateProductInFirestore
} from './firebase/productService';
import { auth } from './firebase/firebaseConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './adminPage.css';

const AdminPage = () => {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    productName: '',
    price: '',
    displayImageLink: '',
    description: '',
    quillContent: '',
    slideshowImages: [],
    colorPrices: [{ color: '', price: '' }], // New field for color-price pairs
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [editingFields, setEditingFields] = useState({
    productName: '',
    price: '',
    description: '',
    colorPrices: [], // New field for editing color-price pairs
  });

  const quillRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const productsFromFirestore = await fetchProducts();
        setProducts(productsFromFirestore);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    loadProducts();
  }, []);

  const handleAddProduct = async () => {
    setLoading(true);
    setSuccess(false);

    try {
      const productData = {
        productName: newProduct.productName,
        price: newProduct.price,
        displayImage: newProduct.displayImageLink,
        description: newProduct.description,
        quillContent: newProduct.quillContent,
        slideshowImages: newProduct.slideshowImages.filter(img => img),
        colorPrices: newProduct.colorPrices.filter(cp => cp.color && cp.price), // Add colorPrices to productData
      };

      await addProductToFirestore(productData);

      const productsFromFirestore = await fetchProducts();
      setProducts(productsFromFirestore);

      setNewProduct({
        productName: '',
        price: '',
        displayImageLink: '',
        description: '',
        quillContent: '',
        slideshowImages: [],
        colorPrices: [{ color: '', price: '' }],
      });
      setSuccess(true);
    } catch (error) {
      console.error('Error adding product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkUpload = (e) => {
    setNewProduct({
      ...newProduct,
      displayImageLink: e.target.value
    });
  };

  const handleSlideshowLinkUpload = (index, e) => {
    const url = e.target.value;
    setNewProduct((prevState) => {
      const newSlideshowImages = [...prevState.slideshowImages];
      newSlideshowImages[index] = url;
      return { ...prevState, slideshowImages: newSlideshowImages };
    });
  };

  const handleDeleteProduct = async (productId) => {
    if (!window.confirm('Are you sure you want to delete this product?')) return;

    try {
      await deleteProductFromFirestore(productId);
      const productsFromFirestore = await fetchProducts();
      setProducts(productsFromFirestore);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditClick = (product) => {
    setEditingProductId(product.id);
    setEditingFields({
      productName: product.productName || '',
      price: product.price || '',
      description: product.description || '',
      colorPrices: product.colorPrices || [], // Include colorPrices in editing fields
    });
  };

  const handleUpdateProduct = async () => {
    if (!editingProductId) return;

    setLoading(true);
    setSuccess(false);

    try {
      const updatedData = {
        productName: editingFields.productName,
        price: editingFields.price,
        description: editingFields.description,
        colorPrices: editingFields.colorPrices, // Include colorPrices in the update
      };

      await updateProductInFirestore(editingProductId, updatedData);
      const productsFromFirestore = await fetchProducts();
      setProducts(productsFromFirestore);
      setEditingProductId(null);
      setSuccess(true);
    } catch (error) {
      console.error('Error updating product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingFields({ ...editingFields, [name]: value });
  };

  const handleCancelEdit = () => {
    setEditingProductId(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleColorPriceChange = (index, field, value) => {
    setNewProduct(prevState => {
      const newColorPrices = [...prevState.colorPrices];
      newColorPrices[index] = { ...newColorPrices[index], [field]: value };
      return { ...prevState, colorPrices: newColorPrices };
    });
  };

  const addColorPriceField = () => {
    setNewProduct(prevState => ({
      ...prevState,
      colorPrices: [...prevState.colorPrices, { color: '', price: '' }]
    }));
  };

  const removeColorPriceField = (index) => {
    setNewProduct(prevState => ({
      ...prevState,
      colorPrices: prevState.colorPrices.filter((_, i) => i !== index)
    }));
  };

  const quillModules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'font': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: () => {
          const url = prompt('Enter image URL:');
          if (url) {
            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'image', url);
          }
        },
      },
    },
  }), []);

  return (
    <div className="admin-page">
      <h1>Admin Page</h1>
      <button onClick={handleLogout} className="logout-button">Log Out</button>
      
      {/* Add Product Form */}
      <div className="input-interface">
        <h2>Add New Product</h2>
        <input
          type="text"
          placeholder="Product Name"
          value={newProduct.productName}
          onChange={(e) => setNewProduct({ ...newProduct, productName: e.target.value })}
        />
        <input
          type="number"
          placeholder="Price"
          value={newProduct.price}
          onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
        />

        <h4>Enter Display Image URL</h4>
        <input
          type="text"
          placeholder="Display Image URL"
          value={newProduct.displayImageLink}
          onChange={handleLinkUpload}
        />

        <h4>Enter Slideshow Image URLs</h4>
        {[...Array(4)].map((_, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Slideshow Image URL ${index + 1}`}
            onChange={(e) => handleSlideshowLinkUpload(index, e)}
          />
        ))}

        <h4>Product Description</h4>
        <input
          type="text"
          placeholder="Product Description"
          value={newProduct.description}
          onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        />

        <h4>Additional Images with Captions (Quill Editor)</h4>
        <ReactQuill
          ref={quillRef}
          value={newProduct.quillContent}
          onChange={(value) => setNewProduct({ ...newProduct, quillContent: value })}
          modules={quillModules}
        />

        <h4>Size and Price Variations</h4>
        {newProduct.colorPrices.map((cp, index) => (
          <div key={index} className="color-price-input">
            <input
              type="text"
              placeholder="Size"
              value={cp.color}
              onChange={(e) => handleColorPriceChange(index, 'color', e.target.value)}
            />
            <input
              type="number"
              placeholder="Price"
              value={cp.price}
              onChange={(e) => handleColorPriceChange(index, 'price', e.target.value)}
            />
            <button onClick={() => removeColorPriceField(index)}>Remove</button>
          </div>
        ))}
        <button onClick={addColorPriceField}>Add Size-Price Variation</button>

        <button onClick={handleAddProduct} disabled={loading}>
          {loading ? <div className="spinner"></div> : 'Add Product'}
        </button>
        {success && <div className="checkmark"></div>}
      </div>

      <h2>Products List</h2>
      <ul className='ul'>
        {products.map((product) => (
          <li key={product.id} className="product-item">
            <div className="product-info">
              <strong>{product.productName}</strong> ₦{product.price}
            </div>
            <div className="product-actions">
              <button onClick={() => handleEditClick(product)}>Edit</button>
              <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
            </div>

            {editingProductId === product.id && (
              <div className="edit-form">
                <h3>Edit Product</h3>
                <input
                  type="text"
                  name="productName"
                  placeholder="New Product Name"
                  value={editingFields.productName}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  name="price"
                  placeholder="New Price"
                  value={editingFields.price}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="description"
                  placeholder="New Description"
                  value={editingFields.description}
                  onChange={handleInputChange}
                />
                
                <h4>Size and Price Variations</h4>
                {editingFields.colorPrices.map((cp, index) => (
                  <div key={index} className="color-price-input">
                    <input
                      type="text"
                      placeholder="Sizes"
                      value={cp.color}
                      onChange={(e) => {
                        const newColorPrices = [...editingFields.colorPrices];
                        newColorPrices[index] = { ...newColorPrices[index], color: e.target.value };
                        setEditingFields({ ...editingFields, colorPrices: newColorPrices });
                      }}
                    />
                    <input
                      type="number"
                      placeholder="Price"
                      value={cp.price}
                      onChange={(e) => {
                        const newColorPrices = [...editingFields.colorPrices];
                        newColorPrices[index] = { ...newColorPrices[index], price: e.target.value };
                        setEditingFields({ ...editingFields, colorPrices: newColorPrices });
                      }}
                    />
                    <button onClick={() => {
                      const newColorPrices = editingFields.colorPrices.filter((_, i) => i !== index);
                      setEditingFields({ ...editingFields, colorPrices: newColorPrices });
                    }}>Remove</button>
                  </div>
                ))}
                <button onClick={() => {
                  setEditingFields({
                    ...editingFields,
                    colorPrices: [...editingFields.colorPrices, { color: '', price: '' }]
                  });
                }}>Add Size-Price Variation</button>

                <div className="edit-buttons">
                  <button onClick={handleUpdateProduct} disabled={loading}>
                    {loading ? <div className="spinner"></div> : 'Update'}
                  </button>
                  <button onClick={handleCancelEdit}>Cancel</button>
                </div>
                {success && <div className="checkmark"></div>}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPage;