import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { ShopContext } from '../context/shop-context';
import { useNavigate } from 'react-router-dom';
import './rando.css';

export default function Rando() {
  const { cartItems, products } = useContext(ShopContext);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [remarks, setRemarks] = useState('');
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [paymentOption, setPaymentOption] = useState('Pay On Delivery');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://checkout.flutterwave.com/v3.js";
    script.async = true;
    script.onload = () => console.log("Flutterwave script loaded");
    script.onerror = () => console.error("Flutterwave script failed to load");
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  

  const navigate = useNavigate();

  const handleCompleteOrder = async () => {
    const cartItemsArray = Object.entries(cartItems).map(([id, quantity]) => {
      const product = products.find(p => p.id === id);
      return {
        productName: product?.productName || 'Unknown',
        price: product?.price || 0,
        quantity,
      };
    });

    const orderData = {
      cartItems: cartItemsArray,
      email,
      phone,
      country,
      firstName,
      lastName,
      address,
      state,
      city,
      area,
      remarks,
    };

    const brevoApiUrl = 'https://api.brevo.com/v3/smtp/email';
    const brevoApiKey = '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';

    const customerEmailContent = {
      sender: { name: 'XYZ AUTOS LIMITED', email: 'support@xyzautoslimited.com' },
      to: [{ email: orderData.email, name: `${orderData.firstName} ${orderData.lastName}` }],
      subject: 'Order Confirmation',
      htmlContent: `
        <h1>Thank you for your order, ${orderData.firstName}!</h1>
        <p>Your order has been placed successfully. Below are your order details:</p>
        <ul>
          ${orderData.cartItems.map(item => 
          `<li>${item.productName}: Quantity: ${item.quantity}, Price: ₦${Number(item.price).toLocaleString('en-NG', { minimumFractionDigits: 2 })}</li>`      ).join('')}
        </ul>
        <p>Total: ₦${orderData.cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toLocaleString('en-NG', { minimumFractionDigits: 2 })}</p>
        <p>We will notify you when your order is shipped.</p>
      `,
    };

    const adminEmailContent = {
      sender: { name: 'XYZ AUTOS LIMITED', email: 'support@xyzautoslimited.com' },
      to: [{ email: 'westernontime@gmail.com', name: 'Admin' }],
      subject: 'New Order Placed',
      htmlContent: `
        <h1>New Order Received!</h1>
        <p>Customer: ${orderData.firstName} ${orderData.lastName}</p>
        <p>Email: ${orderData.email}</p>
        <p>Phone: ${orderData.phone}</p>
        <p>Delivery Address: ${orderData.address}, ${orderData.city}, ${orderData.state}, ${orderData.country}</p>
        <p>Remarks: ${orderData.remarks}</p>
        <p>Order details:</p>
        <ul>
          ${orderData.cartItems.map(item => `<li>${item.productName} (Quantity: ${item.quantity}, Price: ₦${item.price})</li>`).join('')}
        </ul>
        <p>Total: ₦${orderData.cartItems.reduce((total, item) => total + item.price * item.quantity, 0)}</p>
      `,
    };

    try {
      if (paymentOption === 'Pay On Delivery') {
        await axios.post(brevoApiUrl, customerEmailContent, {
          headers: {
            'api-key': brevoApiKey,
            'Content-Type': 'application/json',
          },
        });

        await axios.post(brevoApiUrl, adminEmailContent, {
          headers: {
            'api-key': brevoApiKey,
            'Content-Type': 'application/json',
          },
        });

        setOrderSuccess(true);
        setTimeout(() => {
          setOrderSuccess(false);
          navigate('/');
        }, 4000);
      } else {
        const handleFlutterwavePayment = () => {
          if (window.FlutterwaveCheckout) {
            window.FlutterwaveCheckout({
              public_key: 'FLWPUBK-d526a6831a79516108365bb6367fec21-X',
              tx_ref: `tx_${Date.now()}`, 
              amount: cartItemsArray.reduce((total, item) => total + item.price * item.quantity, 0), 
              currency: 'NGN', 
              payment_options: 'card, banktransfer', 
              customer: {
                email: email, 
                phone_number: phone, 
                name: `${firstName} ${lastName}`,
              },
              callback: async (data) => {

                try {
                  await axios.post(brevoApiUrl, customerEmailContent, {
                    headers: {
                      'api-key': brevoApiKey,
                      'Content-Type': 'application/json',
                    },
                  });
          
                  await axios.post(brevoApiUrl, adminEmailContent, {
                    headers: {
                      'api-key': brevoApiKey,
                      'Content-Type': 'application/json',
                    },
                  });
          
                  setOrderSuccess(true);
                  setTimeout(() => {
                    setOrderSuccess(false);
                    navigate('/'); 
                  }, 4000);
                } catch (error) {
                  console.error('Error handling payment callback:', error);
                }
              },
              onclose: () => {
                console.log('Payment modal closed');
              },
              customizations: {
                title: 'XYZ AUTOS LIMITED', 
                description: 'Payment for items in cart', 
                logo: 'https://xyzautoslimited.com/logo.png',
              },
            });
          } else {
            console.error('FlutterwaveCheckout is not defined');
          }
        };
        

        handleFlutterwavePayment();
      }
    } catch (error) {
      console.error('Error placing the order:', error);
      alert('Failed to place order');
    }
  };

  return (
    <div className="container">
      <div className='left'>
        <div className='tops'>
          <div className='top-left'>
            <h4>Delivery</h4>
            <input 
              className='orderr' 
              placeholder='First name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input 
              className='orderr' 
              placeholder='Last name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />            
            <input 
            className='orderr' 
            type='email' 
            placeholder='Email' 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            className='orderr'  
            type='number' 
            placeholder='Phone/Whatsapp Number' 
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
            <input 
            className='orderr' 
            placeholder='State'
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
            <input 
              className='orderr' 
              placeholder='City'
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input 
              className='orderr' 
              placeholder='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
                    <h2>Remarks (Optional)</h2>
        <input 
          className='orderr' 
          placeholder='Please fill in the remarks' 
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
          </div>
          <div className='top-right'>
          <div className='toptop'>
              <p className='texts'>
                ✅ Please fill in your usual email address when you place an order, 
                we will notify you by email after the item is shipped. ✅ Free Shipping and
                delivery over ₦10,000, buy with confidence. ✅ 
                Estimated 2~4 business days delivery, high quality and cool products are worth your money! 😉.
              </p>
              <ul className='text'>
                <p className='greet'>Greetings to our esteemed customers and please NOTE:</p>
                <li>1. When placing an order, please make sure that your shipping address and phone number are correct.</li>
                <li>2. When you receive the goods, please open the box to check the number of goods and styles, to confirm that there is no damage after signing for payment.</li>
                <li>3. The store sells goods warranty of one year from the date of signing.</li>
                <p>Wish you a happy shopping in our store! Your satisfaction is our driving force!</p>
              </ul>
              <h2 className='texttop'>Choose Payment Option</h2>
              <div className='payment-options'>
                <label>
                  <input
                    type='radio'
                    name='paymentOption'
                    value='Pay On Delivery'
                    checked={paymentOption === 'Pay On Delivery'}
                    onChange={(e) => setPaymentOption(e.target.value)}
                  />
                  Pay On Delivery
                </label>
                <label>
                  <input
                    type='radio'
                    name='paymentOption'
                    value='Pay Online'
                    checked={paymentOption === 'Pay Online'}
                    onChange={(e) => setPaymentOption(e.target.value)}
                  />
                  Pay Online
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='remarks'>
        <button 
          className='completed' 
          onClick={handleCompleteOrder}
        >
          Complete Order
        </button>
      </div>
      {orderSuccess && (
        <div className="order-success-notification">
          <div className="checkmark-container">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            <p>Order Placed Successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
}
